import { ComponentType } from 'react';
import { Loading } from './Loading/Loading';
import { useOktaAuth } from '../contexts/OktaContext';

export function withAuth(WrappedComponent: ComponentType<unknown>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  function WithAuth() {
    const { authState } = useOktaAuth();

    if (authState?.isAuthenticated) {
      return <WrappedComponent />;
    }

    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <Loading />
      </div>
    );
  }

  WithAuth.displayName = `WithAuth(${displayName})`;

  return WithAuth;
}
